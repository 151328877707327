export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const mainLogo = require("../assets/LogoMain.svg");
export const mainImage = require("../assets/right_banner.jpg");
export const logo = require("../assets/image_logo.png");
export const blurImage = require("../assets/blur_image.jpg");
export const laptopImg = require("../assets/laptopLogos.svg");
export const forwordArrow = require("../assets/arrow_forward.png");
export const userImg = require("../assets/employeeCircleLogos.svg");
export const SelectSubscriptionImg = require("../assets/right_banner.jpg");
export const BlurSubscription=require("../assets/right_banner.jpg")
export const FeaturedIcon = require("../assets/Featured icon.png");
export const check = require("../assets/Check icon.png")
export const uncheck = require("../assets/image_(5).png");
export const checked = require ("../assets/checked.png");
export const arrowIconBack = require ("../assets/arrowIconBack.svg");
export const LaptopIMGBlack = require ("../assets/LaptopImg.svg");
export const EmployeeIMGBlack = require ("../assets/EmpImage.svg");
export const RightBlackArrow = require ("../assets/RightBlack.svg");